import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homepage',
    redirect: '/home',
    component: HomeView,
    children: [
      // 首页
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/Home.vue')
      },
      // 技术百科
      {
        path: '/technology',
        name: 'technology',
        component: () => import('../views/Technology.vue'),
      },
      // 百科详情
      {
        path: '/technology-detail',
        name: 'technology-detail',
        component: () => import('../views/Technology-detail.vue')
      },
      // 分类
      {
        path: '/classify',
        name: 'classify',
        component: () => import('../views/Classify.vue')
      },
      // 好物推荐
      {
        path: '/recommend',
        name: 'recommend',
        component: () => import('../views/Recommend.vue')
      },
      // 购物车
      {
        path: '/shop-cart',
        name: 'shop-cart',
        component: () => import('../views/Shop-cart.vue')
      },
      // 商品详情
      {
        path: '/commodity',
        name: 'commodity',
        component: () => import('../views/Commodity.vue')
      },
      // 个人中心
      {
        path: '/my',
        name: 'my',
        component: () => import('../views/My.vue')
      },
      // 会员权益
      {
        path: '/member-vip',
        name: 'member-vip',
        component: () => import('../views/Member-vip.vue')
      },
      // 优惠券
      {
        path: '/coupon',
        name: 'coupon',
        component: () => import('../views/Coupon.vue')
      },
      // 交流平台
      {
        path: '/exchange',
        name: 'exchange',
        component: () => import('../views/Exchange.vue')
      },
      // 交流平台
      {
        path: '/exchange-detail',
        name: 'exchange-detail',
        component: () => import('../views/Exchange-detail.vue')
      },
      // 我的余额
      {
        path: '/my-balance',
        name: 'my-balance',
        component: () => import('../views/My-balance.vue')
      },
      // 收货地址
      {
        path: '/shipping-address',
        name: 'shipping-address',
        component: () => import('../views/Shipping-address.vue')
      },
      // 新增收货地址
      {
        path: '/add-address',
        name: 'add-address',
        component: () => import('../views/Add-address.vue')
      },
      // 待付款
      {
        path: '/to-paid',
        name: 'to-paid',
        component: () => import('../views/To-paid.vue')
      },
      // 已付款
      {
        path: '/already-paid',
        name: 'already-paid',
        component: () => import('../views/Already-paid.vue')
      },
      // 已发货
      {
        path: '/shipped',
        name: 'shipped',
        component: () => import('../views/Shipped.vue')
      },
      // 已完成
      {
        path: '/completed',
        name: 'completed',
        component: () => import('../views/Completed.vue')
      },
      // 邀请码
      {
        path: '/invite-code',
        name: 'invite-code',
        component: () => import('../views/Invite-code.vue')
      },
      // 客服中心
      {
        path: '/customer-service',
        name: 'customer-service',
        component: () => import('../views/Customer-service.vue')
      },
       // 直播咨询
       {
        path: '/direct-seeding',
        name: 'direct-seeding',
        component: () => import('../views/Direct-seeding.vue')
      },
    ]
  },
  //登录 
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  // 注册
  {
    path: '/logon',
    name: 'logon',
    component: () => import('../views/Logon.vue')
  },
  // 找回密码
  {
    path: '/retrieve-password',
    name: 'retrieve-password',
    component: () => import('../views/Retrieve-password.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
