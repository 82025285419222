<template>
  <div class="homepage">
    <el-container>
      <el-header
        :style="{ backgroundColor: $route.path == '/home' || $route.path == '/technology' ? '' : '#fff' }, { position: $route.path == '/home' || $route.path == '/technology' ? 'absolute' : '' }"
        style="height: 1.55rem;">
        <navTab></navTab>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
  </div>
</template>

<script>
import navTab from '@/components/navTab.vue';
export default {
  name: 'homepage',
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {},
  created() {
  },
  components: {
    navTab
  }
}
</script>

<style lang="scss" scoped>
.homepage {
  width: 100%;
  height: 100%;
  font-family: PingFangSC-Medium, PingFang SC;

  .el-container {
    width: 100%;
    height: 100%;
  }

  .el-header {
    width: 100%;
    margin: 0;
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }

  .el-main {
    margin: 0;
    padding: 0;
    height: 100%;
  }
}
</style>
