<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app{
  // width: 100%;
  height: 100%;
}
</style>
