<template>
    <div class="nav-tab" :style="{ backgroundColor: $route.path == '/home' || $route.path == '/technology' ? '' : '#fff' }">
        <header style="height: 1.55rem;">
            <h2 :style="{ color: $route.path == '/home' || $route.path == '/technology' ? '#fff' : '#000' }">拓创</h2>
            <!-- <navTab></navTab> -->
            <ul>
                <li v-for="item in list" :key="item.id" @click="handleClick(item.path)"
                    :class="{ active: activeId == item.path }"
                    :style="{ color: $route.path == '/home' || $route.path == '/technology' ? '#fff' : '#000' }">{{
                        item.text }}
                </li>
            </ul>
            <div class="search">
                <img src="../assets/img/home/home_search.png" alt="">
                <input type="text" placeholder="输入搜索内容" v-model="searchinput">
            </div>
            <div class="login" :style="{ color: $route.path == '/home' || $route.path == '/technology' ? '#fff' : '#000' }"
                @click="$router.push('/login')">
                登录
            </div>
            <div class="logon" :style="{ color: $route.path == '/home' || $route.path == '/technology' ? '#fff' : '#000' }"
                @click="$router.push('/logon')">
                注册
            </div>
        </header>
    </div>
</template>

<script>
export default {
    name: 'navtab',
    data() {
        return {
            list: [{
                id: '1',
                text: '首页',
                path: '/home'
            },
            {
                id: '2',
                text: '技术百科',
                path: '/technology'
            }, {
                id: '3',
                text: '分类',
                path: '/classify'
            },
            {
                id: '4',
                text: '购物车',
                path: '/shop-cart'
            }, {
                id: '5',
                text: '个人中心',
                path: '/my'
            }],
            activeId: null,
            searchinput: ''
        };
    },
    methods: {
        handleClick(url) {
            this.$router.push(url)
            this.activeId = url
            // console.log(url);
        }
    },
    computed: {},
    created() {
        this.activeId = this.$route.path
    }
}
</script>

<style lang="scss" scoped>
.nav-tab {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 1.5rem;

    header {
        width: 100%;
        height: 1.55rem;
        display: flex;
        color: #fff;
        align-items: center;
        font-size: .3rem;
        font-weight: 400;

        h2 {
            font-size: .475rem;
            font-family: YouSheBiaoTiHei;
            line-height: .6125rem;
        }

        ul {
            list-style: none;
            display: flex;
            margin-left: 4rem;

            li {
                margin: 0 .5rem;
                cursor: pointer;
                color: #000000;
                line-height: .4125rem;
                position: relative;
            }

            .active {

                &::before {
                    content: '';
                    width: .8125rem;
                    height: .025rem;
                    background: #3E80FF;
                    position: absolute;
                    bottom: -0.125rem;
                    left: 50%;
                    transform: translateX(-50%)
                }
            }

        }

        .search {
            width: 2.5rem;
            height: .55rem;
            background: rgba(0, 0, 0, 0.3);
            border-radius: .275rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            overflow: hidden;
            margin-left: .85rem;

            img {
                width: .25rem;
                height: .275rem;
                margin-left: .2rem;
                margin-right: .25rem;
            }

            input {
                flex: 1;
                border: 0;
                outline: none;
                font-size: .2rem;
                font-weight: 500;
                color: #FFFFFF;
                line-height: .275rem;
                background-color: transparent;
            }
        }

        .login {
            margin-left: .5rem;
            cursor: pointer;
        }

        .logon {
            margin-left: .725rem;
            cursor: pointer;
        }
    }
}
</style>